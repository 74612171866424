import React, { Component } from 'react';
import styles from 'assets/scss/Qualification.module.scss'

export default class Solo extends Component {
    populateRound = () => {
        const {stage_detail} = this.props.main;
        let list = new Array(10).fill(null);
        
        let data = stage_detail?.stage_info?.win_list;
        if (data) {
            for (let index = 0; index < data.length; index++) {
                list[index] = data[index]
            }
        }

        return list.map((dt, idx) => {
            let img = '';
            switch (dt) {
                case true:
                    img = 'kualifikasi_win'
                    break;
                case false:
                    img = 'kualifikasi_lose'
                    break;
                default:
                    img = 'kualifikasi_not announced'
                    break;
            }

            return (
                <div key={idx} className={styles.round_item}>
                    <img src={`https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/${img}.png`} alt="" />
                </div>
            )

        });
    }

    renderPrize = (type, req = null) => {
        if (type === 1) {
            return (
                <>
                    <p>HADIAH MENANG</p>
                    <p>{req} KALI</p>
                </>
            )
        }
        else
        {
            return <p>HADIAH LOLOS</p>
        }
    }

    populateReward = () => {
        const {stage_detail} = this.props.main;
        let list = [];

        if (stage_detail?.rewards) {
            list = stage_detail.rewards.map((data, idx) => 
                <div key={idx} className={styles.prize}>
                    <div className={styles.text}>
                        {/* { this.renderPrize(data.req_type, data.req) } */}
                        <p>{data.claim_text}</p>
                    </div>
                    <div 
                        className={`${styles.icon} ${(data.can_claim && !data.is_claimed) ? styles.active : ''} ${(data.is_claimed) ? styles.disabled : ''}`} 
                        onClick={() => (data.can_claim && !data.is_claimed) ? this.props.actionsMain.claim(data.id) : ""}
                    >
                        <img src={data.img_url} alt=""/>
                    </div>
                </div>
            )
        }

        return list;
    }

    renderText = () => {
        const {stage, current_stage} = this.props.main;

        // GET CURRENT STAGE DETAIL
        let filtered = stage.stages[current_stage - 1]

        if (filtered) {
            if (filtered.is_finished === true) {
                return "TELAH BERAKHIR" 
            }
            else if (filtered.is_open === true) {
                return "SEDANG BERJALAN"
            }
            else
            {
                return "BELUM DIMULAI"
            }
        }
        else
        {
            return "BELUM DIMULAI"
        }
    }

    render() {
        const {stage_detail, login, current_stage} = this.props.main;

        return (
            <div className="animate__animated animate__fadeInUp">
                <div className={styles.stage}>
                    <div className={styles.current_stage}>
                        {`Kualifikasi Solo - Week ${current_stage}`} : {this.renderText()}
                    </div>
                    <div className={styles.rank}>
                        Rank {login.codm_rank} (+{stage_detail?.stage_info?.win_point})
                    </div>
                </div>
                <div className={styles.progress}>
                    <div className={styles.title}>
                        POIN {stage_detail?.stage_info?.total_points}
                    </div>
                    <div className={styles.bar}>
                        <div className={styles.progress_bar} />
                        <div className={styles.current_progress} style={{width: `${stage_detail?.stage_info?.total_points <= 100 ? stage_detail?.stage_info?.total_points : 100}%`}} />
                        <div className={styles.limiter} style={{left: `${stage_detail?.stage_info?.min_points}%`}}>
                            <div className={styles.point}> QUALIFY POIN {stage_detail?.stage_info?.min_points} </div>
                        </div>
                    </div>
                </div>

                <div className={styles.round}>
                    <div className={styles.title}>
                        <div className={styles.text}>
                            RONDE GAME {stage_detail?.stage_info?.win_list?.length}/{stage_detail?.stage_info?.match_cap}
                        </div>
                        <div className={styles.stats}>
                            <div className={styles.stat_item}>
                                <div className={styles.icon}>
                                    <img src="https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/kualifikasi_win.png" alt="" />
                                </div>
                                <div className={styles.text}>
                                    MENANG: {stage_detail?.stage_info?.win}
                                </div>
                            </div>
                            <div className={styles.stat_item}>
                                <div className={styles.icon}>
                                    <img src="https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/kualifikasi_lose.png" alt="" />
                                </div>
                                <div className={styles.text}>
                                    KALAH: {stage_detail?.stage_info?.lose}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={styles.round_items}>
                        {this.populateRound()}
                    </div>
                </div>
                <div className={styles.prizes}>
                    {this.populateReward()}
                </div>
            </div>
        )
    }
}
