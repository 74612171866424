export const image = [
    'https://cdn.garenanow.com/gstaticid/AOV/esports/ingamequalifiermj8/reward/Callingcard-deadlands.png',
    'https://cdn.garenanow.com/gstaticid/AOV/esports/ingamequalifiermj8/reward/m16-hellion.png',
    'https://cdn.garenanow.com/gstaticid/AOV/esports/ingamequalifiermj8/reward/EpicCreditCrates.png',
    'https://cdn.garenanow.com/gstaticid/AOV/esports/ingamequalifiermj8/reward/PDW57-hotspot.png',
    'https://cdn.garenanow.com/gstaticid/AOV/esports/ingamequalifiermj8/reward/Otter-SandDrift.png',
    'https://cdn.garenanow.com/gstaticid/AOV/esports/ingamequalifiermj8/reward/M4-FeralStalker.png',
    'https://cdn.garenanow.com/gstaticid/AOV/esports/ingamequalifiermj8/reward/CBR4-Wildstyle.png',
    'https://cdn.garenanow.com/gstaticid/AOV/esports/ingamequalifiermj8/reward/Type-25-Sanguine.png',
    'https://cdn.garenanow.com/gstaticid/AOV/esports/ingamequalifiermj8/reward/QXR.png',
    'https://cdn.garenanow.com/gstaticid/AOV/esports/ingamequalifiermj8/reward/Minotaur-MazeKeeper.png',
    'https://cdn.garenanow.com/gstaticid/AOV/esports/ingamequalifiermj8/reward/Credit5000.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/background.jpg',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/background_2new.jpg',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/background_2.jpg',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/BG.jpg',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/board.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/BottomBG.jpg',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_agree.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_bubar.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_daftar sekarang.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_disagree.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_info hadiah_active.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_info hadiah_inactive.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_kirim undangan.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_klaim hadiah.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_konfirmasi.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_kualifikasi_not selected.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_kualifikasi_selected.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_menunggu.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_menu_info hadiah_active.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_menu_info hadiah_inactive.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_menu_kualifikasi_active.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_menu_kualifikasi_inactive.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_menu_leaderboard tim_active.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_menu_leaderboard tim_inactive.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_menu_peraturan_active.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_menu_peraturan_inactive.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_peraturan_active.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_peraturan_inactive.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_slice.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_sudah diklaim.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_terima undangan.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_tolak undangan.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/kualifikasi_lose.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/kualifikasi_not announced.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/kualifikasi_win.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/menu_board.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/menu_header.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/rewards_purple.png',
    'https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/teammate_board.png',
    'https://codmtw.cdn.garenanow.com/mgames/codmID/h5/240104_MajorSeriesS10/background.jpg',
    'https://codmtw.cdn.garenanow.com/mgames/codmID/h5/240104_MajorSeriesS10/Tournament_BG_sponsor.jpg',
    'https://codmtw.cdn.garenanow.com/mgames/codmID/h5/240104_MajorSeriesS10/bg_base.jpg'
]