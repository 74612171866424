import axios from 'axios';
import { getUrlParam, removeParamUrl } from 'helper';

const ls = window.localStorage;

const cdn = axios.create({
    baseURL: `https://cdngarenanow-a.akamaihd.net/devid/${process.env.REACT_APP_LOCALIZE_NAME}/`,
    withCredentials: false,
    transformRequest: [(data, headers) => {
        delete headers[process.env.REACT_APP_TOKEN_HEADER_NAME]
        return data
    }]
})

const cdn_err = axios.create({
    baseURL: `https://cdngarenanow-a.akamaihd.net/webid/TranslateCommon/`,
    withCredentials: false,
    transformRequest: [(data, headers) => {
        delete headers[process.env.REACT_APP_TOKEN_HEADER_NAME]
        return data
    }]
});

export const toggle_popup = (bool, tipe) => ({
    type: 'TOGGLE_POPUP',
    bool,
    tipe
})

export const toggle_loader = (data) => ({
    type: 'TOGGLE_LOADER',
    data: data
})

export const put_data = (key, data) => ({
    type: "PUT_DATA",
    key,
    data
})

/**
 * 
 * Example of how to call API
 * 
 */
export const login = () => {
    return dispatch => {
        dispatch(toggle_loader(true))

        axios.get(process.env.REACT_APP_API_ENDPOINT)
            .then((resp) => {
                dispatch(put_data('login', resp.data))
            })
            .catch((err) => {
                dispatch(catch_error(err?.response?.data?.msg || err?.response?.data?.message));
            })
            .then(() => {
                dispatch(toggle_loader(false))
            })
    }
}

/**
 * 
 * Example of how to implement multi language 
 * 
 */
export const get_translate = () => {
    return dispatch => {
        cdn.get(`data.json?${Math.random() * 10000}`)
            .then(resp => {
                // dispatch(put_data('json', resp.data.data[0]))
                // console.log(`resp.data.data`, resp.data.data.api_error)
                dispatch(put_data('json', resp.data.data))
                dispatch({
                    type: 'GET_JSON_DIRECTUS',
                    data: resp.data.data[0]
                })
            })
            .catch(err => {
                console.log(err)
            })
    }
}

/**
 * 
 * Example of how to catch error if not logged in
 * 
 */
export const catch_error = (err_msg) => {
    return (dispatch) => {
        if (err_msg === 'not_logged_in') {
            const param = {
                accessToken: getUrlParam("access_token") || "",
                accountId: getUrlParam("account_id") || "",
                region: getUrlParam("region") || ""
            };

            ls.removeItem('cache');
            ls.removeItem('hash');

            window.location.href = `${`${process.env.REACT_APP_BASE_URL}?access_token=${param.accessToken}&account_id=${param.accountId}&region=${param.region}`}`;
        } else {
            if (err_msg) {
                dispatch(put_data('err_msg', err_msg))
            }
            dispatch(toggle_popup(true, 'error'))
            dispatch(toggle_loader(false))
        }
    }
}

export const check_login = (batch = false) => {
    return dispatch => {
        dispatch(toggle_loader(true));

        axios.get('oauth/check_login/')
            .then(res => {
                dispatch(put_data('login', res.data));
                if (batch || res.data.is_registered) {
                    dispatch(get_necessary())
                }
            })
            .catch(err => {
                dispatch(toggle_popup(true, 'login'))
            })
            .then(() => {
                dispatch(toggle_loader(false));
            })

    }
}

export const registration = () => {
    return dispatch => {
        dispatch(toggle_loader(true));

        axios.post('api/register/')
            .then(res => {
                dispatch(check_login(true))
            })
            .catch(err => {
                dispatch(put_data('popup_text', err?.response?.data?.msg || err?.response?.data?.message))
                dispatch(toggle_popup(true, 'alert'))
            })
            .then(() => {
                dispatch(toggle_loader(false));
            })
    }
}

const get_necessary = () => {
    return dispatch => {
        dispatch(stage());
        dispatch(leaderboard());
    }
}

export const leaderboard = () => {
    return dispatch => {
        axios.get('api/leaderboard/')
            .then(res => {
                dispatch(put_data('leaderboard', res.data.leaderboard))
            })
    }
}

export const stage = () => {
    return dispatch => {
        axios.get('api/stage/')
            .then(res => {
                dispatch(put_data('stage', res.data))
                dispatch(stage_detail(res.data.active))

                if (res.data.active === 5) {
                    dispatch(get_invite())
                }
            })
    }
}

export const stage_detail = (param) => {
    return dispatch => {
        dispatch(toggle_loader(true));

        // axios.get(`api/stage_detail/?stage=5`)
        axios.get(`api/stage_detail/?stage=${param}`)
            .then(res => {
                dispatch(put_data('current_stage', param))
                dispatch(put_data('stage_detail', res.data))
            })
            .catch(err => {
                let err_msg = err?.response?.data?.msg || err?.response?.data?.message;
                dispatch(put_data('current_stage', param))
                dispatch(put_data('stage_detail', false))
                if (err_msg === 'not_qualified') {
                    dispatch(put_data('stage_detail', err?.response?.data?.msg || err?.response?.data?.message))
                }
                // else
                // {
                //     dispatch(put_data('popup_text', err?.response?.data?.msg))
                //     dispatch(toggle_popup(true, 'alert'))
                // }
            })
            .then(() => {
                dispatch(toggle_loader(false));
            })
    }
}

export const claim = (data) => {
    return (dispatch, getState) => {
        dispatch(toggle_loader(true));

        const { main } = getState()

        axios.post('api/claim/', { item: data })
            .then(res => {
                dispatch(stage_detail(main.current_stage));
                dispatch(put_data('popup_text', res?.data?.name))
                dispatch(put_data('popup_img', res?.data?.img_url))
                dispatch(toggle_popup(true, 'prize'))
            })
            .catch(err => {
                dispatch(put_data('popup_text', err?.response?.data?.msg || err?.response?.data?.message))
                dispatch(toggle_popup(true, 'alert'))
            })
            .then(() => {
                dispatch(toggle_loader(false));
            })
    }
}

export const invite = (data) => {
    return dispatch => {
        dispatch(toggle_loader(true));

        axios.post('api/invite/', data)
            .then(res => {
                dispatch(stage_detail(5))
            })
            .catch(err => {
                dispatch(put_data('popup_text', err?.response?.data?.msg || err?.response?.data?.message))
                dispatch(toggle_popup(true, 'alert'))
                dispatch(toggle_loader(false));
            })
    }
}

export const confirm = (data) => {
    return dispatch => {
        dispatch(toggle_loader(true));

        axios.post('api/confirm/', data)
            .then(res => {
                dispatch(toggle_popup(false, 'confirm'))
                dispatch(stage_detail(5));
            })
            .catch(err => {
                dispatch(put_data('popup_text', err?.response?.data?.msg || err?.response?.data?.message))
                dispatch(toggle_popup(true, 'alert'))
                dispatch(toggle_loader(false));
            })
    }
}

export const accept = (data) => {
    return dispatch => {
        dispatch(toggle_loader(true));
        dispatch(put_data('throttle', true))
        axios.post('api/accept/', data)
            .then(res => {
                dispatch(toggle_popup(false, 'confirm'))
                dispatch(put_data('invite', false))
                dispatch(stage_detail(5));
            })
            .catch(err => {
                dispatch(put_data('popup_text', err?.response?.data?.msg || err?.response?.data?.message))
                dispatch(toggle_popup(true, 'alert'))
                dispatch(toggle_loader(false));
            })
            .then(res => {
                setTimeout(() => {
                    dispatch(put_data('throttle', false))
                }, 500)
            })
    }
}

export const reject = (data) => {
    return dispatch => {
        dispatch(toggle_loader(true));

        axios.post('api/reject/', data)
            .then(res => {
                dispatch(toggle_popup(false, 'confirm'))
                dispatch(put_data('invite', false))
                dispatch(stage_detail(5));
            })
            .catch(err => {
                dispatch(put_data('popup_text', err?.response?.data?.msg || err?.response?.data?.message))
                dispatch(toggle_popup(true, 'alert'))
                dispatch(toggle_loader(false));
            })
    }
}

export const kick = (data) => {
    return dispatch => {
        dispatch(toggle_loader(true));

        axios.post('api/kick/', data)
            .then(res => {
                dispatch(toggle_popup(false, 'confirm'))
                dispatch(stage_detail(5));
            })
            .catch(err => {
                dispatch(put_data('popup_text', err?.response?.data?.msg || err?.response?.data?.message))
                dispatch(toggle_popup(true, 'alert'))
                dispatch(toggle_loader(false));
            })
    }
}

export const get_invite = () => {
    return dispatch => {
        axios.get('api/invite/')
            .then(res => {
                // console.log(res.data)
                dispatch(put_data('invite', res.data))
            })
            .catch(err => {
                dispatch(put_data('invite', false))
            })
    }
}

export const dissolve = (data) => {
    return dispatch => {
        dispatch(toggle_loader(true));

        axios.post('api/dissolve/', data)
            .then(res => {
                dispatch(toggle_popup(false, 'confirm'))
                dispatch(stage_detail(5));
            })
            .catch(err => {
                dispatch(put_data('popup_text', err?.response?.data?.msg || err?.response?.data?.message))
                dispatch(toggle_popup(true, 'alert'))
                dispatch(toggle_loader(false));
            })
    }
}

export const setToken = () => {
    return (dispatch) => {
        var token = getUrlParam('token');
        if (token) {
            ls.setItem('token', token);
            removeParamUrl();
            window.location.href = window.location.origin;
        }
        else {
            if (getUrlParam('err')) {
                dispatch(put_data('popup_text', getUrlParam('err')))
                dispatch(toggle_popup(true, 'alert'))
            }
            else {
                dispatch(check_login())
            }
        }
    };
};

export const get_err_translate = () => {
    return dispatch => {
        cdn_err.get(`${'ID'}.json?${Math.floor(Math.random() * 10000)}`)
            .then(resp => {
                dispatch({
                    type: 'GET_ERR_GENERAL',
                    data: resp.data
                })
                dispatch(get_translate());
            })
    }
}

export const mapErr = (key) => {
    return (dispatch, getState) => {
        const { err_map } = getState().main;
        if (err_map && err_map[key]) {
            return err_map[key]
        } else {
            return key
        }
    }
}
