const main_state = {
	login : false,
	loader: false,
	json: false,
	popup_open: false,
	popup_type: false,
	popup_text: '',
	popup_img: '',
	popup_callback: false,

	stage : false,
	stage_detail : false,
	leaderboard: false,
	current_stage: false,
	invite: false,
	err_map: false,

	throttle:false
}

const main = (state = main_state, action) => {
    switch (action.type) {
		case "PUT_DATA":
			return { ...state, [action.key]: action.data };
		case "TOGGLE_LOADER":
			return { ...state, loader: action.data}
		case "TOGGLE_POPUP":
			return { ...state, popup_open: action.bool, popup_type: action.tipe}
		case "GET_ERR_GENERAL":
			return { ...state, err_map: action.data }
		case "GET_JSON_DIRECTUS":
			return { ...state, err_map: {...state.err_map, ...action.data}}
		default:
			return state;
	}
};

export default main;