import React, { Component } from 'react'
import styles from 'assets/scss/Qualification.module.scss'

export default class Invited extends Component {

    getCaptain = () => {
        const {invite} = this.props.main;
        let filtered = invite.members.filter(dt => dt.status === 'captain')
        return decodeURIComponent(filtered[0].codm_nickname);
    }

    getMembers = () => {
        const {invite, login} = this.props.main;
        return invite.members.map((dt, idx) => {
            if (dt.account_id !== login.t_open_id && dt.status === 'joined') {
                return <div>{decodeURIComponent(dt.codm_nickname)}</div>
            } else {
                return ""
            }
        })
    }

    handleConfirm = (bool) => {
        const {put_data, toggle_popup, accept, reject} = this.props.actionsMain;
        let text = `Apakah kamu yakin ${bool ? "menerima" : "menolak"} undangan tim dari ${this.getCaptain()} ?`;
        put_data('popup_text', text);
        put_data('popup_callback', bool ? accept : reject);
        toggle_popup(true, 'confirm');
    }

    render() {
        const {invite} = this.props.main;

        return (
            <div className="animate__animated animate__fadeInUp">
                <div className={styles.stage}>
                    <div className={styles.create_team}>
                        Selamat! Kamu berhasil masuk ke Babak Kualifikasi Tim. Terima undangan sekarang!
                    </div>
                </div>

                <div className={styles.invitation_cont}>
                    <div className={styles.row}>
                        <div className={styles.col}>NAMA TIM</div>
                        <div className={styles.col}>CAPTAIN TIM</div>
                        <div className={styles.col}>ANGGOTA TIM</div>
                        <div className={styles.col}></div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.col}>{invite?.team_name}</div>
                        <div className={styles.col}>{this.getCaptain()}</div>
                        <div className={styles.col}>{this.getMembers()}</div>
                        <div className={styles.col}>
                            <div className={styles.btn} onClick={() => this.handleConfirm(true)}>
                                <img src="https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_terima undangan.png" alt=""/>
                            </div>
                            <div className={styles.btn} onClick={() => this.handleConfirm(false)}>
                                <img src="https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_tolak undangan.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
