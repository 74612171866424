import React, { Component } from 'react';
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";

// Component
import ViewportRestrict from 'helper/component/viewport';
import Loader from 'helper/component/loader';
import NotFound from 'helper/component/404';
import { preloadImage, setAnalytics } from 'helper';
import { image } from 'helper/image';

// Pages
import Unregistered from 'pages/Unregistered';
import Leaderboard from 'pages/Leaderboard';
import ErrorBoundary from 'ErrorBoundary';
import Qualification from 'pages/Qualification';
import Rewards from 'pages/Rewards';
import Rules from 'pages/Rules';
import BaseModal from 'partial/BaseModal';

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainActions from 'redux/actions/main';

//GA
setAnalytics();

class Main extends Component {
	componentDidMount() {
		const { setToken, get_err_translate } = this.props.actionsMain;
		setToken();
		get_err_translate();
		preloadImage(image);
	}

	render() {
		const { loader } = this.props.main
		return (
			<Router basename={process.env.PUBLIC_URL}>
				<div id="Main" className="main-panel">
					<ErrorBoundary>
						<ViewportRestrict display={true} type="portrait" />
						<Loader loader={loader} />
						<div className="content-container">
							<Switch>
								<Route exact path="/" component={Unregistered} />
								<Route exact path="/kualifikasi" component={Qualification} />
								<Route exact path="/hadiah" component={Rewards} />
								<Route exact path="/leaderboard" component={Leaderboard} />
								<Route exact path="/peraturan" component={Rules} />
								<Route component={NotFound} />
							</Switch>
						</div>
						<BaseModal />
					</ErrorBoundary>
				</div>
			</Router>
		);
	}
}

function mapStateToProps(state) {
	return { main: state.main }
}

function mapDispatchToProps(dispatch) {
	return {
		actionsMain: bindActionCreators(mainActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);