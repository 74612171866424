import Layout from 'pages/Layout';
import React, { Component } from 'react';
import 'assets/scss/Leaderboard.scss'

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainActions from 'redux/actions/main';

class Leaderboard extends Component {
    
    populateLeaderboard = () => {
        let list = [];
        const {leaderboard} = this.props.main;

        if (leaderboard) {
            list = leaderboard.map((data, idx) =>
                <div key={idx} className="tr">
                    <div className="td">No. {idx + 1}</div>
                    <div className="td">{data.total_points}</div>
                    <div className="td">{data.name}</div>
                    <div className="td">{data.members.map((m, i) => <span key={i}>{decodeURIComponent(m.codm_nickname)}</span>)}</div>
                </div>
            )
        }
        return list;
    }

    render() {
        const {leaderboard} = this.props.main;

        return (
            <Layout>
                <div className="leaderboard">
                    {
                        leaderboard ? (
                            <div className="table">
                                <div className="thead animate__animated animate__fadeIn">
                                    <div className="tr">
                                        <div className="th">Rank</div>
                                        <div className="th">Poin</div>
                                        <div className="th">Nama Tim</div>
                                        <div className="th">Anggota Tim</div>
                                    </div>
                                </div>
                                <div className="tbody animate__animated animate__fadeInUp">
                                    {this.populateLeaderboard()}
                                </div>
                            </div>
                        ) : (
                            <p className="not_open animate__animated animate__fadeIn">
                                Saat ini Leaderboard belum bisa diakses
                            </p>
                        )
                    }
                </div>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
	return { main: state.main }
}

function mapDispatchToProps(dispatch) {
	return {
		actionsMain: bindActionCreators(mainActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Leaderboard);
