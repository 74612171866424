import React, { Component } from 'react';
import styles from 'assets/scss/Unregistered.module.scss'

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainActions from 'redux/actions/main';

class Unregistered extends Component {
    
    state = {
        tab: 0
    }

    handleTab = (data) => {
        this.setState({
            tab: data
        })
    }

    componentDidUpdate() {
        const {login} = this.props.main;

        if (login?.is_registered) {
            this.props.history.push('/kualifikasi');
        }
    }

    render() {
        const {registration} = this.props.actionsMain;
        const {json} = this.props.main;
        const {tab} = this.state;

        return (
            <div className={styles.unregistered}>
                <div className={styles.content}>
                    <div className={styles.board}>
                        <div className={styles.board_button}>
                            <div className={`${styles.button} ${tab === 0 ? styles.active : ''} ${styles.info}`} onClick={() => this.handleTab(0)}>
                                <img src="https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_info hadiah_inactive.png" alt=""/>
                            </div>
                            <div className={`${styles.button} ${tab === 1 ? styles.active : ''} ${styles.rule}`} onClick={() => this.handleTab(1)}>
                                <img src="https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_info hadiah_inactive.png" alt=""/>
                            </div>
                        </div>
                        <div className={styles.board_content}>
                            <div className={`${styles.hadiah} ${tab === 0 ? styles.active : ''}`}>
                                <img src="https://codmtw.cdn.garenanow.com/mgames/codmID/h5/240104_MajorSeriesS10/reward_banner.jpeg" alt="" />
                            </div>
                            <div className={`${styles.peraturan} ${tab === 1 ? styles.active : ''}`}>
                                <img src="https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/prize/Banner_Reward.jpg" alt="" />
                                <div dangerouslySetInnerHTML={{__html:json?.peraturan}} />
                            </div>
                        </div>
                    </div>
                    <div className={styles.button} onClick={() => registration()}>
                        <img src="https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_daftar sekarang.png" alt="" />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
	return { main: state.main }
}

function mapDispatchToProps(dispatch) {
	return {
		actionsMain: bindActionCreators(mainActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Unregistered);