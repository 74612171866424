import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import axios from 'axios';
// import * as serviceWorker from './serviceWorker'; // UNCOMMENT FOR PWA

// COMPONENT & OTHER
import 'react-responsive-modal/styles.css';
import 'animate.css/animate.min.css';
import 'assets/scss/index.scss';
import Main from 'base/Main';

// REDUX
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import rootReducer from 'redux/reducers';

// INITIALIZE GA

ReactGA.initialize([
    {
        trackingId: process.env.REACT_APP_GA,
        gaOptions: {
            name: 'website',
        }
    },
    {
        trackingId: process.env.REACT_APP_GA_ERROR_REPORT,
        gaOptions: {
            name: 'error_report',
        }
    }
])
ReactGA.pageview(window.location.pathname + window.location.search, ['website']);

// SET REDUX STORE
const store = createStore(
    rootReducer,
    applyMiddleware(thunk)
)

// SET DEFAULT AXIOS
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

if (process.env.REACT_APP_USE_TOKEN === 'true') {
    var token = window.localStorage.getItem('token');
    axios.defaults.headers[process.env.REACT_APP_TOKEN_HEADER_NAME] = token;
}

// SET AXIOS INTERCEPTOR
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    
    const config = {
        method: error?.response?.config?.method,
        status_code: error?.response?.status,
        endpoint: error?.response?.request?.responseURL
    };
    
    const { method, status_code, endpoint } = config;

    // Run only on production
    if (process.env.NODE_ENV === "production") {
        // Check status code, if below 5xx, don't send. Send report only 5xx error
        if (status_code > 499) {
            console.log("SEND ERROR REPORTING")
            // Will send data like 'www.FE.com -> https://www.BE.com/api/error/
            ReactGA.event({
                category: `${window.location.hostname} -> ${endpoint}`,
                action: method,
                label: status_code.toString(),
                nonInteraction: true
            }, ['error_report'])
        }
    }


    return Promise.reject(error);
});

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <Main />
        </React.StrictMode>
    </Provider>,
    document.getElementById('root')
);


// serviceWorker.unregister(); // UNCOMMENT TO REMOVE PWA || COMMENT THE REGISTER
// serviceWorker.register(); // UNCOMMENT FOR PWA
