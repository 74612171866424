import Layout from 'pages/Layout';
import React, { Component } from 'react';
import styles from 'assets/scss/Rules.module.scss'

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainActions from 'redux/actions/main';


class Rules extends Component {

    render() {
        const {json} = this.props.main;

        const rulesStaticData = `
        <p>Periode In-Game Qualifier: <strong>30 Desember 2021 - 30 Januari 2022</strong></p><br/>
        <p><strong>Solo Qualifier</strong><br/>Setiap player harus terdaftar terlebih dahulu dengan menekan tombol "Daftar Sekarang" saat pertama kali membuka halaman in-game qualifier. Player setidaknya harus mengumpulkan 60 poin untuk dapat mengikuti Team Qualifier dengan cara bermain ranked match secara solo selama babak Solo Qualifier. Terdapat 4 week dalam babak Solo Qualifier dan player hanya perlu lolos di salah satu week Solo Qualifier untuk dapat lanjut ke babak Team Qualifier.</p><br/>
        <p><strong>Team Qualifier</strong><br/>Setelah lolos dalam salah satu week solo qualifier, player dapat membentuk tim dengan player lainnya yang juga dinyatakan lolos saat Solo Qualifier. Semua anggota tim harus bermain ranked match secara bersamaan sebanyak 30 kali untuk mengumpulkan poin. Top 192 tim dengan jumlah poin tertinggi akan lanjut ke babak Team Elimination.</p>`
        return (
            <Layout>
                {
                    json &&
                    <div className={styles.rules}>
                        { json?.peraturan !== "" ? 
                            (<div ref={this.myRef} className='animate__animated animate__fadeInUp' dangerouslySetInnerHTML={{__html: json?.peraturan}} />) : 
                            (<div ref={this.myRef} className='animate__animated animate__fadeInUp' dangerouslySetInnerHTML={{__html: rulesStaticData}} />)
                        }
                    </div>
                }
            </Layout>
        );
    }
}

function mapStateToProps(state) {
	return { main: state.main }
}

function mapDispatchToProps(dispatch) {
	return {
		actionsMain: bindActionCreators(mainActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Rules);