import React, { Component } from 'react';
import { Modal } from 'react-responsive-modal';

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainActions from 'redux/actions/main';

class BaseModal extends Component {

    handleLogin = () => {
        window.location.href = process.env.REACT_APP_API_ENDPOINT + 'oauth/login';
    }

    handleClose = () => {
        const { popup_open } = this.props.main;
        this.props.actionsMain.put_data('popup_open', !popup_open);
    }

    renderContent = () => {
        const { popup_type, popup_text, popup_img, popup_callback, json, throttle } = this.props.main;
        const { mapErr } = this.props.actionsMain;

        switch (popup_type) {
            case 'login':
                return (
                    <div className="content login">
                        <p>Silahkan Login Terlebih Dahulu</p>
                        <div className="btn-cont">
                            <div className="btn" onClick={() => this.handleLogin()}>
                                <img src="https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_agree.png" alt="" />
                                <p>LOGIN</p>
                            </div>
                        </div>
                    </div>
                )
            case 'alert':
                return (
                    <div className="content alert">
                        <p>{json?.[mapErr(popup_text)] ?? mapErr(popup_text) ?? popup_text}</p>
                    </div>
                )
            case 'prize':
                return (
                    <div className="content prize">
                        <h2>Selamat!</h2>
                        <p>Kamu telah mendapatkan</p>
                        <img src={popup_img} alt="" />
                        <p>{popup_text}</p>
                    </div>
                )
            case 'confirm':
                return (
                    <div className="content confirm">
                        <p>{popup_text}</p>

                        <div className="btn-cont">
                            <div className="btn" onClick={!throttle && popup_callback}>
                                <img src="https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_agree.png" alt="" />
                                <span>YA</span>
                            </div>
                            <div className="btn" onClick={this.handleClose}>
                                <img src="https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/ingame_qualification_v2/btn_disagree.png" alt="" />
                                <span>TIDAK</span>
                            </div>
                        </div>
                    </div>
                )
            default:
                return;
        }
    }

    render() {
        const { popup_open, popup_type, popup_text } = this.props.main;
        const no_close_list = [
            'not_logged_in',
            'event_closed',
            'event_not_started',
            'server_err',
            'event_maintenance',
            'event_restricted',
            'no_jwt_token',
            'not_logged_in',
            'no_character',
            'invalid_region',
            'event_end'
        ]
        return (
            <Modal
                open={popup_open}
                onClose={() => this.handleClose()}
                focusTrapped={false}
                closeOnOverlayClick={false}
                showCloseIcon={popup_type === 'login' || no_close_list.includes(popup_text) ? false : true}
                closeOnEsc={popup_type === 'login' || no_close_list.includes(popup_text) ? false : true}
                classNames={{
                    modal: 'customModal',
                    closeButton: 'customCloseButton',
                    overlay: 'customOverlay'
                }}
                center
            >
                <div className="container">
                    {this.renderContent()}
                </div>
            </Modal>
        )
    }
}


function mapStateToProps(state) {
    return { main: state.main }
}

function mapDispatchToProps(dispatch) {
    return {
        actionsMain: bindActionCreators(mainActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BaseModal);