import Layout from 'pages/Layout';
import React, { Component } from 'react';
import styles from 'assets/scss/Rewards.module.scss'

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainActions from 'redux/actions/main';

class Rewards extends Component {
    render() {
        const { json } = this.props.main;
        const { registration_prize, solo_prize, team_prize } = json;

        return (
            <Layout>
                <div className={styles.rewards}>
                    <div className='animate__animated animate__fadeInUp'>
                        <div className={styles.content}>
                            <h3>HADIAH REGISTRASI</h3>
                            <div className={styles.prize_cont}>
                                {registration_prize?.map((prize, i) => (
                                    <div key={i} className={styles.prize}>
                                        <img src={prize.image} alt=""/>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={styles.content}>
                            <h3>KUALIFIKASI SOLO</h3>
                            <p>Kualifikasi berlangsung selama 4 minggu. Data akan diupdate maksimum 3 jam setelah match berakhir.</p>
                            <div className={styles.prize_cont}>
                                {solo_prize?.map((prize, i) => (
                                    <div key={i} className={styles.prize}>
                                        <img src={prize.image} alt=""/>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={styles.content}>
                            <h3>KUALIFIKASI TIM</h3>
                            <p>Kualifikasi berlangsung selama 4 minggu. Data akan diupdate maksimum 3 jam setelah match berakhir.</p>
                            <div className={styles.prize_cont}>
                                {team_prize?.map((prize, i) => (
                                    <div key={i} className={styles.prize}>
                                        <img src={prize.image} alt=""/>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
	return { main: state.main }
}

function mapDispatchToProps(dispatch) {
	return {
		actionsMain: bindActionCreators(mainActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Rewards);