import React, { Component } from 'react'
import styles from 'assets/scss/Qualification.module.scss';

export default class NotQualified extends Component {
    render() {
        return (
            <div className={"animate__animated animate__fadeInUp " + styles.not_qualified}>
                <div>
                    <span> Kamu tidak dapat mengikuti Kualifikasi Tim pada turnamen ini. </span>
                    <span> Jangan patah semangat, sampai jumpa di turnamen lainnya </span>
                </div>
            </div>
        )
    }
}
