import Layout from 'pages/Layout';
import React, { Component } from 'react';
import Solo from './Solo';
import Team from './Team';
import Create from './Create';
import Invited from './Invited';
import NotQualified from './NotQualified';

import moment from 'moment';
import 'moment/locale/id';
import styles from 'assets/scss/Qualification.module.scss'


// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainActions from 'redux/actions/main';

class Qualification extends Component {

    handleChangeStage = (id) => {
        this.props.actionsMain.stage_detail(id);
    }

    populateStage = () => {
        const {stage, current_stage} = this.props.main;
        let server_time = Date.parse(stage.server_time);
        let list = []


        if (stage?.stages) {
            list = stage.stages.map((data, idx) => {
                return (
                    <div 
                        key={idx} 
                        className={` ${styles.timeline_item} ${current_stage === data.id ? styles.active : ''} ${((data.id === 5) && server_time < Date.parse(data.start_time)) ? styles.disabled : ''} `}
                        onClick={() => this.handleChangeStage(data.id)}
                    >
                        <div className={styles.title}>
                            {data.id !== 5 ? `Kualifikasi Solo - Week ${data.id}` : "Kualifikasi Tim"}
                        </div>
                        <div className={styles.date}>
                            {
                                `${moment(data.start_time).format('D MMMM')} - ${moment(data.end_time).format('D MMMM')}`
                            }
                        </div>
                    </div>
                )
            })}

        return list;
    }

    handleRender = () => {
        const {stage_detail, invite, current_stage} = this.props.main;

        if (stage_detail) {
            if (current_stage === 5) {
                if (invite) {
                    return <Invited {...this.props} />
                }
                else if(stage_detail === 'not_qualified')
                {
                    return <NotQualified {...this.props} />
                }
                else if (stage_detail?.stage_info?.status !== "complete") {
                    return <Create {...this.props} />
                }
                else if (stage_detail?.stage_info?.status === "complete") {
                    return <Team {...this.props} />
                }
            }
            else
            {
                return <Solo {...this.props} />
            }
        }
       
        return false;
    }

    render() {
        const {stage} = this.props.main;

        return (
            <Layout>
                <div className={styles.qualification}>
                    {
                        stage.active === 0 ?
                        (
                            <p className={styles.not_open}>Kualifikasi belum dibuka</p>
                        ) 
                        :
                        (
                            <>
                                <div className="animate__animated animate__fadeIn">
                                    <div className={styles.timeline}>
                                        {this.populateStage()}
                                    </div>
                                </div>
                                
                                {this.handleRender()}
                            </>
                        )
                    }
                    

                </div>
            </Layout >
        );
    }
}

function mapStateToProps(state) {
	return { main: state.main }
}

function mapDispatchToProps(dispatch) {
	return {
		actionsMain: bindActionCreators(mainActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Qualification);